@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Ubuntu:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-color: #f8f3f0;
}
